import React from "react";
import { Provider } from "react-redux";
import store from "./src/store/store";
import SwaggerUI from "swagger-ui";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import AuthProvider from "./src/components/Auth/AuthContext";

export const wrapRootElement = ({ element }) => (
    <Provider store={store}>
        <AuthProvider>
            {element}
        </AuthProvider>
    </Provider>
);
