import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { adNetworksReducer } from './adNetworksSlice';

const rootReducer = combineReducers({
  adNetworks: adNetworksReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
